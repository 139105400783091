import type { RouteLocationNormalized } from "vue-router"

import { captureMessage } from "@sentry/vue"

import type { TypePermission } from "@/types"

import { useUserStore } from "@/store/user"

import { can } from "@/utils/helpers"

import i18n from "@/plugins/i18n"

interface MetaTagDefinition {
	[key: string]: string
}

export function updatePageTitleAndMeta(document: Document, to: RouteLocationNormalized): void {
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title)
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags)

	if (nearestWithTitle) {
		document.title = i18n.global.t(nearestWithTitle.meta.title as string) + " | " + i18n.global.t("app-name")
	}

	Array.from(document.querySelectorAll("[data-vue-router-controlled]")).forEach((el) => {
		el.parentNode?.removeChild(el)
	})

	if (!nearestWithMeta) return
	;(nearestWithMeta.meta.metaTags as MetaTagDefinition[]).forEach((tagDef) => {
		const tag = document.createElement("meta")
		Object.keys(tagDef).forEach((key) => {
			tag.setAttribute(key, tagDef[key])
		})
		tag.setAttribute("data-vue-router-controlled", "")
		document.head.appendChild(tag)
	})
}

export async function handleUnauthorizedAccess(to: RouteLocationNormalized) {
	const userStore = useUserStore()
	const mainPrivatePage = { name: "Main" }
	const authPage = { name: "Auth" }

	// Check if user is logged in
	if (!userStore.isLoggedIn) {
		const isUserInitialized = await userStore.init()
		if (!isUserInitialized) {
			if (to.name === "Auth") return true // Prevent infinite loop
			if (to.meta.public)
				return true // Allow public pages
			else return authPage // Redirect to login page
		}
	}
	// User is logged in now
	if (to.name === "Auth") return mainPrivatePage // Redirect to main page
	if (to.meta.public)
		return true // Allow public pages
	else {
		// On private pages, check if user has permission
		if (to.meta.permission) {
			if (can(to.meta.permission as TypePermission)) return true
			else {
				// User does not have permission - redirect to main page
				captureMessage(`auth:router:permission: ${to.meta.permission}`)
				if (to.name === "Main")
					return authPage // No permission on main page! - redirect to login page
				else return mainPrivatePage
			}
		} else return true // Allow private pages without permission
	}
}
