import axios, { type AxiosResponse } from "axios"

import type {
	TypeTrain,
	TypeResponsePagination,
	TypeGetRequestParamsPagination,
	TypeHashData,
	TypeTrainOrdering,
	TypeOccupation,
	TypeSubTask,
	TypeTask,
	TypeGroup,
	TypeMaintenancePlan,
	TypeShift,
	TypeMaintenancePlanEdited,
	TypeSubTrackMatrix,
	TypeInspection,
	TypeUser,
	TypeArchive,
	TypeMaintenanceFact,
	TypeMaintenanceFactEdited,
	TypeStaffField,
	TypeStaffEdited,
	TypeWorkshop,
	TypeOccupationEdited,
	TypeCode,
	TypeOccupationOrdering,
	TypeGroupEdited,
	TypeTaskEdited,
	TypeSubTaskEdited,
	TypeTaskDict,
	TypeTrainFilters,
	TypeOccupationFilters,
	TypeOccupationStatisticsResponse,
	TypeOccupationStatisticsPeriod,
	TypeDepot,
	TrackOccupationsForDepot,
	TypeTrainEdited,
	TypeTrack
} from "@/types"

import ApiDoc from "@/api/api-doc"
import ApiSecure from "@/api/api-secure"

import { response2element } from "@/utils/helpers"

export function getUserInfo(quiet: boolean = true): Promise<TypeUser> {
	return ApiSecure.get("profile/self/", { NO_REDIRECT: true, QUIET: quiet } as never)
}

export function getGetTokensByHash(hash: string): Promise<AxiosResponse<TypeHashData>> {
	return axios.get(`http://217.148.217.231:11010/token/${hash}/`)
}

// 1. TASK

export function getTasksByGroup(params: { task_group__screen_id__in: string }): Promise<{ results: TypeTaskDict }> {
	return ApiSecure.get("tasks/grouped/", { params })
}

export function postTask(data: TypeTaskEdited): Promise<TypeTask> {
	return ApiSecure.post("tasks/", data)
}

export function getTask(id: TypeTask["id"]): Promise<TypeTask> {
	return ApiSecure.get(`tasks/${id}/`)
}

export function putTask(id: TypeTask["id"], data: TypeTaskEdited): Promise<TypeResponsePagination<TypeTask>> {
	return ApiSecure.put(`tasks/${id}/`, data)
}

// 2. SUB TASK

export function postSubTask(data: TypeSubTaskEdited): Promise<TypeSubTask> {
	return ApiSecure.post("sub_tasks/", data)
}

export function putSubTask(id: TypeSubTask["id"], data: TypeSubTaskEdited): Promise<TypeSubTask> {
	return ApiSecure.put(`sub_tasks/${id}/`, data)
}

// 3. TASK GROUP

export function getTaskGroups(params: { screen_id: string }): Promise<TypeResponsePagination<TypeGroup>> {
	return ApiSecure.get("task_groups/", { params })
}

export function postTaskGroup(data: TypeGroupEdited): Promise<TypeGroup> {
	return ApiSecure.post("task_groups/", data)
}

export function getTaskGroup(id: TypeGroup["id"]): Promise<TypeGroup> {
	return ApiSecure.get(`task_groups/${id}/`)
}

export function putTaskGroup(id: TypeGroup["id"], data: TypeGroupEdited): Promise<TypeGroup> {
	return ApiSecure.put(`task_groups/${id}/`, data)
}

// 4. MAINTENANCE PLAN

export function getMaintenancePlan(params: {
	shift_id: TypeShift["id"]
}): Promise<TypeResponsePagination<TypeMaintenancePlan>> {
	return ApiSecure.get(`maintenance_plan/`, { params })
}

export function postMaintenancePlan(data: TypeMaintenancePlanEdited): Promise<TypeMaintenancePlan> {
	return ApiSecure.post("maintenance_plan/", data)
}

export function putMaintenancePlan(
	id: TypeMaintenancePlan["id"],
	data: TypeMaintenancePlanEdited
): Promise<TypeMaintenancePlan> {
	return ApiSecure.put(`maintenance_plan/${id}/`, data)
}

export function deleteMaintenancePlan(id: TypeMaintenancePlan["id"]) {
	return ApiSecure.delete(`maintenance_plan/${id}/`)
}

export async function getMaintenancePlanPdfFile(data: {
	shift_id: TypeShift["id"]
	note?: string
}): Promise<null | HTMLAnchorElement> {
	const response = await ApiDoc.post(`maintenance_plan/download_pdf_file/`, data, {
		responseType: "blob"
	})
	if (response.shouldDownload) {
		return response2element(response)
	}
	return null
}

// 5. SUB TRACK

export function getSubTracksMatrix(): Promise<TypeSubTrackMatrix> {
	return ApiSecure.get("sub_tracks/")
}

export function getTracks(params: { depot_id?: TypeDepot["id"] }): Promise<TypeResponsePagination<TypeTrack>> {
	return ApiSecure.get("tracks/", { params })
}

// 6. TRAIN

export function getTrains(
	params?: TypeGetRequestParamsPagination<TypeTrainOrdering, TypeTrainFilters>
): Promise<TypeResponsePagination<TypeTrain>> {
	return ApiSecure.get("trains/", { params })
}

export function getTrainById(id: TypeTrain["id"]): Promise<TypeTrain> {
	return ApiSecure.get(`trains/${id}/`)
}

export function putTrain(id: TypeTrain["id"], data: TypeTrainEdited): Promise<TypeTrain> {
	return ApiSecure.put(`trains/${id}/`, data)
}

// 7. SHIFT

export function getCurrentShift(): Promise<Array<TypeShift>> {
	return ApiSecure.get("shifts/get_by_date/")
}

export function getShifts(): Promise<TypeResponsePagination<TypeShift>> {
	return ApiSecure.get("shifts/")
}

// 8. INSPECTION

export function getInspections(): Promise<TypeResponsePagination<TypeInspection>> {
	return ApiSecure.get("inspections/")
}

// 9. ARCHIVE

export function getArchive(params: { date: string }): Promise<TypeArchive> {
	return ApiSecure.get("archive/get_by_date/", { params })
}

export async function getMaintenancePlanArchive(
	params: { document_id: string },
	fileName: string
): Promise<null | HTMLAnchorElement> {
	const response = await ApiDoc.get("maintenance_plan/download/", {
		params,
		responseType: "blob"
	})
	if (response.shouldDownload) {
		return response2element(response, fileName)
	}
	return null
}
// 10. MAINTENANCE FACT
export function putMaintenanceFact(
	id: TypeMaintenanceFact["id"],
	data: TypeMaintenanceFactEdited
): Promise<TypeMaintenanceFact> {
	return ApiSecure.put(`maintenance_fact/${id}/`, data)
}

export function getMaintenanceFact(params: {
	shift_id: TypeShift["id"]
}): Promise<TypeResponsePagination<TypeMaintenanceFact>> {
	return ApiSecure.get(`maintenance_fact/`, { params })
}

export async function getMaintenanceFactPdfFile(data: {
	shift_id: TypeShift["id"]
	note?: string
}): Promise<null | HTMLAnchorElement> {
	const response = await ApiDoc.post(`maintenance_fact/download_pdf_file/`, data, {
		responseType: "blob"
	})
	if (response.shouldDownload) {
		return response2element(response)
	}
	return null
}

export function getMaintenanceFactStaff(id: TypeShift["id"]): Promise<TypeStaffField[]> {
	return ApiSecure.get(`staff_types/for_shift/?shift_id=${id}`)
}
export function putMaintenanceFactStaff(data: {
	shift_id: TypeShift["id"]
	flattened: TypeStaffEdited
}): Promise<TypeStaffField[]> {
	return ApiSecure.post("staff_types/put_for_shift/", data)
}

// 11. TRACK OCCUPATION

export function getTrackOccupationsForDepot(): Promise<TrackOccupationsForDepot> {
	return ApiSecure.get("track_occupations/depot/")
}

export function getTrackOccupations(
	params?: TypeGetRequestParamsPagination<TypeOccupationOrdering, TypeOccupationFilters>
): Promise<TypeResponsePagination<TypeOccupation>> {
	return ApiSecure.get("track_occupations/", { params })
}

export function postTrackOccupation(data: TypeOccupationEdited): Promise<TypeOccupation> {
	return ApiSecure.post("track_occupations/", data)
}

export function putTrackOccupation(id: TypeOccupation["id"], data: TypeOccupationEdited): Promise<TypeOccupation> {
	return ApiSecure.put(`track_occupations/${id}/`, data)
}

export function deleteTrackOccupation(id: TypeOccupation["id"]) {
	return ApiSecure.delete(`track_occupations/${id}/`)
}

export async function getTrackOccupationsReport(
	params?: TypeGetRequestParamsPagination<TypeOccupationOrdering, TypeOccupationFilters>
): Promise<null | HTMLAnchorElement> {
	const response = await ApiDoc.get("track_occupations/latest_data_file/", {
		responseType: "blob",
		params
	})
	if (response.shouldDownload) {
		return response2element(response)
	}
	return null
}

// 12. TRACK OCCUPATION: CODES

export function getTrackOccupationCodes(): Promise<TypeResponsePagination<TypeCode>> {
	return ApiSecure.get("toc_codes/")
}

export function getTrackOccupationCodesById(id: TypeCode["id"]): Promise<TypeCode> {
	return ApiSecure.get(`toc_codes/${id}/`)
}

// 13. TRACK OCCUPATION: STATISTICS

export async function getTrackOccupationStatistics(
	period: TypeOccupationStatisticsPeriod,
	workshopId: TypeWorkshop["id"],
	controller: AbortController
): Promise<TypeOccupationStatisticsResponse> {
	return await ApiSecure.get("track_occupations/statistics/", {
		params: {
			value: period.value,
			type: period.state,
			workshop_id: workshopId
		},
		signal: controller.signal
	})
}

// 14. Workshops

export function getWorkshops(params: {
	toc: boolean
	depot_id?: TypeDepot["id"]
}): Promise<TypeResponsePagination<TypeWorkshop>> {
	return ApiSecure.get("workshops/", { params })
}

// 15. DEPOT

export function getDepots(): Promise<TypeResponsePagination<TypeDepot>> {
	return ApiSecure.get("depots/")
}
