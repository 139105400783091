// Utilities
import {
	mdiChartBubble,
	mdiFormatListBulleted,
	mdiHammerWrench,
	mdiPoll,
	mdiRoadVariant,
	mdiStickerCheckOutline,
	mdiTimetable,
	mdiTrain
} from "@mdi/js"
import { defineStore } from "pinia"

import type { TypePage } from "@/types"

import { can } from "@/utils/helpers"
import {
	CODE_READ,
	MAINTENANCE_READ,
	TOC_DATA_READ,
	TOC_READ,
	TOC_STAT_READ,
	TRAIN_READ,
	TURNOVER_READ
} from "@/utils/mappers"

import i18n from "@/plugins/i18n"

type appState = {
	isRouterLoading: boolean
	isContentLoading: boolean
	isSwitchingLanguage: boolean
	showMenus: boolean
	width: number
	height: number
	mobileBreakpoint: number
	snackbar: boolean
	snackbarMessage: string
	snackbarType: string
	snackbarTimeout: number
	snackbarTimeoutID: NodeJS.Timeout | undefined
	pages: Array<TypePage>
}
export const useAppStore = defineStore("app", {
	state: (): appState => ({
		isRouterLoading: true,
		isContentLoading: false,
		isSwitchingLanguage: false,
		showMenus: true,
		width: 0,
		height: 0,
		mobileBreakpoint: 600,
		snackbar: false,
		snackbarMessage: "",
		snackbarType: "",
		snackbarTimeout: 4000,
		snackbarTimeoutID: undefined,
		pages: [
			{
				title: i18n.global.t("pages.track-occupation.title"),
				icon: mdiRoadVariant,
				routeName: "TrackOccupation",
				permission: TOC_READ,
				pages: [
					{
						title: i18n.global.t("pages.track-occupation.data.title"),
						icon: mdiFormatListBulleted,
						permission: TOC_DATA_READ,
						routeName: "TrackOccupationData"
					},
					{
						title: i18n.global.t("pages.track-occupation.trains.title"),
						icon: mdiTrain,
						permission: TRAIN_READ,
						routeName: "TrackOccupationTrains"
					},
					{
						title: i18n.global.t("pages.track-occupation.codes.title"),
						icon: mdiChartBubble,
						permission: CODE_READ,
						routeName: "TrackOccupationCodes"
					},
					{
						title: i18n.global.t("pages.track-occupation.statistics.title"),
						icon: mdiPoll,
						permission: TOC_STAT_READ,
						routeName: "TrackOccupationStatistics"
					}
				]
			},
			{
				title: i18n.global.t("pages.planner.title"),
				icon: mdiStickerCheckOutline,
				permission: "PLANER_READ",
				routeName: "Planner"
			},
			{
				title: i18n.global.t("pages.maintenance.title"),
				icon: mdiHammerWrench,
				permission: MAINTENANCE_READ,
				routeName: "Maintenance"
			},
			{
				title: i18n.global.t("pages.turnover.title"),
				icon: mdiTimetable,
				permission: TURNOVER_READ,
				routeName: "Turnover"
			}
		]
	}),

	getters: {
		smallScreen: (state: appState) => state.width < state.mobileBreakpoint,
		contentHeight: (state: appState) => state.height - (state.width > 960 ? 64 : 56),
		isReady: (state: appState) => !state.isContentLoading && !state.isRouterLoading,
		getMobileBreakpoint: (state: appState) => state.mobileBreakpoint,
		getPages: (state: appState) =>
			state.pages
				.filter((page) => can(page.permission))
				.map((page) => ({
					...page,
					pages: page.pages ? page.pages.filter((subPage) => can(subPage.permission)) : undefined
				}))
	},
	actions: {
		reset() {
			this.$reset()
		},
		router(value: boolean): void {
			this.isRouterLoading = value
			this.showMenus = !value
		},
		onResize() {
			if (typeof window === "undefined") return
			this.width = window.innerWidth
			this.height = window.innerHeight
		},
		snackbarReset() {
			if (this.snackbarMessage || this.snackbarType) {
				this.snackbar = false
				this.snackbarMessage = ""
				this.snackbarType = ""
			}
		},
		snackbarHide() {
			if (this.snackbar && (this.snackbarMessage || this.snackbarType)) {
				this.snackbar = false
			}
		},
		async snackbarResetWithDelay() {
			clearTimeout(this.snackbarTimeoutID)
			this.snackbarHide()
			setTimeout(async () => {
				this.snackbarReset()
			}, 100)
		},
		routerLoading(value: boolean) {
			this.isRouterLoading = value
			this.showMenus = !value
		},
		setSnackbar(value: boolean) {
			if (value) {
				this.snackbar = value
			} else {
				this.snackbarResetWithDelay()
			}
		},
		setSnackbarData(message: string = i18n.global.t("snackbar.message-default"), type = "info", timeout = 4000) {
			clearTimeout(this.snackbarTimeoutID)
			this.snackbar = true
			this.snackbarMessage = message
			this.snackbarType = type
			this.snackbarTimeout = timeout
			this.snackbarTimeoutID = setTimeout(async () => {
				await this.snackbarResetWithDelay()
			}, timeout)
		}
	}
})
