import type { TypePermission } from "@/types"
import type { AxiosResponse } from "axios"

import { useUserStore } from "@/store/user"

import { PERMISSIONS_ALL } from "@/utils/mappers"

export const seconds2string = (seconds: number, short = false, timezone?: string): string => {
	if (!seconds) return "-"
	const date = new Date(seconds * 1000)
	const userStore = useUserStore()
	const timeZone = timezone || userStore.user.timezone.name
	if (short) {
		// Return only the time for the short format
		return date.toLocaleString("ru-RU", {
			hour: "numeric",
			minute: "numeric",
			timeZone
		})
	} else {
		// Return the full date and time
		const localeString = date.toLocaleString("ru-RU", {
			hour: "numeric",
			minute: "numeric",
			second: "2-digit",
			day: "numeric",
			month: "numeric",
			year: "2-digit",
			timeZone
		})
		if (localeString.endsWith("23:59:59")) {
			return localeString.replace(", 23:59:59", "") //Remove the time (, 23:59:59)
		}
		return localeString.slice(0, -3) // Remove the seconds (:SS)
	}
}

export const seconds2ISO = (seconds?: number, timezone?: string): string => {
	if (!seconds) return ""
	const ms = seconds * 1000
	const dateUTC = new Date(ms)
	const userStore = useUserStore()
	const timeZone = timezone || userStore.user.timezone.name
	let date = dateUTC.toLocaleString("sv-SE", {
		timeZone
	})
	date = date.replace(" ", "T").replace(",", "")
	if (date.endsWith("T23:59:59")) {
		date = date.slice(0, -9) // Remove T23:59:59
	} else {
		date = date.slice(0, -3) // Remove the seconds (:SS)
	}
	return date
}

export const ISO2backend = (ISO: string): string => {
	// backend expects the time in the format "YYYY-MM-DDTHH:MM:SS"
	// If the time is not provided, it defaults to 23:59:59
	const userStore = useUserStore()
	const timeZone = userStore.user.timezone.value
	if (ISO.includes("T")) return ISO + ":00" + timeZone
	return ISO + "T23:59:59" + timeZone
}

const isSameDay = (secondsStart: number, secondsEnd: number): boolean => {
	const startDate = new Date(secondsStart * 1000)
	const endDate = new Date(secondsEnd * 1000)
	return startDate.toDateString() === endDate.toDateString()
}

export const formatShiftTime = (startSeconds: number, endSeconds: number) => {
	if (isSameDay(startSeconds, endSeconds)) {
		// If the start and end are on the same day, show the date once followed by the start and end times
		return `${seconds2string(startSeconds, false, "UTC")} — ${seconds2string(endSeconds, true, "UTC")}`
	} else {
		// If they span different days, show full details for both
		return `${seconds2string(startSeconds, false, "UTC")} — ${seconds2string(endSeconds, false, "UTC")}`
	}
}

const mimeToExtension: Record<string, string> = {
	"application/pdf": ".pdf",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
	"application/vnd.ms-excel": ".xls"
}

export const response2element = (response: AxiosResponse, fileName?: string): HTMLAnchorElement => {
	const bp = response.data
	const blob = new Blob([bp], {
		type: response.headers["content-type"]
	})
	const elem = document.createElement("a")
	const URL = window.URL || window.webkitURL
	const downloadUrl = URL.createObjectURL(blob)
	elem.href = downloadUrl
	elem.style.display = "none"
	elem.download = getFileName(response, fileName)
	document.body.appendChild(elem)
	setTimeout(function () {
		document.body.removeChild(elem)
		URL.revokeObjectURL(downloadUrl)
	}, 100)
	return elem
}

export const getFileName = (response: AxiosResponse, fileName?: string): string => {
	let nameFromHeader = response.headers["content-disposition"]?.split("filename*=")[1]
	nameFromHeader = nameFromHeader?.split("''")[1]
	nameFromHeader = nameFromHeader ? decodeURIComponent(nameFromHeader) : null

	const contentType = response.headers["content-type"]
	const extension = mimeToExtension[contentType] || ".txt"

	if (fileName) {
		return fileName + extension
	} else if (nameFromHeader) {
		return nameFromHeader
	} else {
		return `file${extension}`
	}
}

export const getBackendDate = (date: string): string => {
	const [year, month, day] = date.split("-")
	return `${day}-${month}-${year}`
}

export const can = (permission?: TypePermission): boolean => {
	if (!permission) return true
	const permissions = useUserStore().getPermissions
	if (!permissions) return false
	if (permissions.includes(PERMISSIONS_ALL)) return true
	return permissions.includes(permission)
}
