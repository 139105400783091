import { captureException, captureMessage } from "@sentry/vue"

import type { TypeError, TypeResponseWithErrors } from "@/types"
import type { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from "axios"

import { useAppStore } from "@/store/app"

import { AxiosAbortReasonParallel, AxiosAbortReasonUnauthorized } from "@/utils/mappers"

import i18n from "@/plugins/i18n"
import authService from "@/utils/auth/auth.service"

export function setParamLang(config: AxiosRequestConfig) {
	let params = config.params
	if (params) {
		if (params.has("lang")) params.delete("lang")
		params.append("lang", i18n.global.locale)
	} else {
		params = new URLSearchParams({ lang: i18n.global.locale })
	}
	config.params = params
	return config
}

export function cancelRequest(reason: string, timeoutMs = 0, abortController = new AbortController()): AbortSignal {
	setTimeout(() => abortController.abort(reason), timeoutMs)
	return abortController.signal
}

export function snackbar(payload: AxiosError<TypeResponseWithErrors<TypeError>>) {
	let snackbarMessage = i18n.global.t("snackbar.message-default")
	if (payload.response?.data) {
		if (payload.response.status === 500) {
			const data = payload.response.data as unknown
			const parser = new DOMParser()
			const htmlDoc = parser.parseFromString(data as string, "text/html")
			const errorDetail = htmlDoc.querySelector("title")?.textContent
			snackbarMessage = errorDetail || snackbarMessage
		} else {
			const errorsArr = payload.response.data.errors
			if (errorsArr && errorsArr.length > 0) {
				snackbarMessage = ""
				errorsArr.forEach(({ field, message }: Record<string, string>) => {
					if (field === "non_field_error") {
						snackbarMessage += `${message}\n`
					} else {
						snackbarMessage += `<strong>${field}:</strong> ${message}\n`
					}
				})
			}
		}
	}
	const appStore = useAppStore()
	appStore.setSnackbarData(snackbarMessage, "warning", 10000)
}

let refreshTokenPromise: Promise<string | null> | undefined = undefined
let controller: AbortController | undefined = undefined

export async function axiosRequestInterceptorOnFulfilled(config: InternalAxiosRequestConfig) {
	if (!refreshTokenPromise) {
		controller = new AbortController()
		refreshTokenPromise = authService.getValidAccessToken().then((token) => {
			refreshTokenPromise = undefined
			controller = undefined
			return token
		})
	}
	return refreshTokenPromise.then((token) => {
		if (token) {
			// config.signal = cancelRequest(10000) logout is not needed when server is not responsive??
			config.headers.Authorization = `Bearer ${token}`
		} else {
			config.signal = cancelRequest(AxiosAbortReasonUnauthorized, 0, controller)
		}
		return config
	})
}

export async function axiosRequestInterceptorOnRejected(payload: AxiosError) {
	captureMessage("api:request:rejected")
	captureException(payload)
	refreshTokenPromise = undefined
}

export function axiosResponseInterceptorOnRejected(payload: any) {
	captureMessage("api:response:rejected")
	captureException(payload)
	const isAbortedParallel = payload.config?.signal?.reason === AxiosAbortReasonParallel
	const isAbortedUnauthorized = payload.config?.signal?.reason === AxiosAbortReasonUnauthorized
	const isUnauthorizedError = payload.response?.status === 401
	if (isAbortedUnauthorized || isUnauthorizedError) {
		authService.logout(!payload.config.NO_REDIRECT)
	}
	if (payload.config.QUIET || isAbortedParallel) return
	snackbar(payload)
}
